<template>
  <div class="audit-modal">
    <div class="loading loading-lg mt-2" v-if="loading"></div>
    <div v-else>
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Data</th>
          <th>Usuário</th>
          <th>Evento</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(event, i) in events" :key="i">
          <td>{{ event.at | date('DD/MM/YYYY HH:mm') }}</td>
          <td>
            {{ event.by.name || '--' }}
            <span v-if="event.by.origin"
                  class="text-info text-small">(@{{ event.by.origin }})</span>
          </td>
          <td>{{ eventDescription(event) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as status from 'src/data/appointment-statuses';
import * as type from '@/data/appointment-types';

export default {
  props: {
  },
  data() {
    return {
      status,
      type,
      events: [],
      loading: false,
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    ...mapState({
      form: state => state.appointment.form,
    }),
  },
  methods: {
    async load() {
      this.loading = true;

      try {
        const { data } = await this.$http.get(`/appointments/${this.form.id}`);
        this.events = data.events;
      } catch (e) {
        this.$toast.error(e);
      } finally {
        this.loading = false;
      }
    },
    eventDescription(event) {
      if (event.type === 'status') {
        return this.status.getDescription(event.value);
      }
      if (event.type === 'account') {
        if (event.value === 'created') {
          return 'Conta criada';
        }
        if (event.value === 'deleted') {
          return 'Conta excluída';
        }
      }
      return [event.type, event.value].join(' @ ');
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';
</style>
